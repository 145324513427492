<template>
  <section>
    <land-hero-alt
      :title="heroAlt.title"
      :cover="heroAlt.cover"
      :assist-color="heroAlt.assistColor"
      :divisible="heroAlt.divisible"
    />
    <!-- <pricing-plan />
    <theme-product-marking />
    <product-services /> -->
    <land-contact-way />
    <land-speed-dial
      :activator="speedDial.activator"
      :items="speedDial.items"
      @click="onSpeedDial"
    />
  </section>
</template>

<script>
  import mixSpeedDial from '@/pages/mixins/mix.speeddial.js'

  export default {
    components: {
      // PricingPlan: () => import('@/pages/sections/PricingPlan.vue'),
      // ThemeProductMarking: () => import('@/pages/sections/ThemeProductMarking.vue'),
      // ProductServices: () => import('@/pages/sections/ProductServices.vue')
    },
    metaInfo: { title: '企业服务' },
    mixins: [mixSpeedDial],
    data () {
      return {
        heroAlt: {
          title: '企业服务',
          cover: 'https://resources.landcoo.com/28330064396156928.jpg',
          assistColor: 'primary',
          divisible: false,
          items: [],
        },
      }
    },
    created () {
      this.speedDial.items.push(
        this.dialHome,
        this.dialQuestionnaire,
        this.dialComplaints,
        this.dialMaintain,
        this.dialReport
      )
    }
  }
</script>
